	// -----------------------------------//
	// FOOTER
	// -----------------------------------//

	.body_5490 footer {
		overflow: visible;

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 50px 0;
			overflow: hidden;
		}
	}

	footer {
		margin: 0 auto;
		clear: both;
		background: rgba(#846B99, .95);
		padding: 50px 0 120px;
		position: relative;
		overflow: hidden;

		&:before {
			@include before(100%, 550px);
			background: url(/i/design/footer-bg.png) no-repeat bottom left;
			bottom: 0;
			left: 0;

			@media only screen and (max-width: 1500px) {
				opacity: .1;
			}

			@media only screen and (max-width: 990px) {
				display: none;
			}
		}

		@media only screen and (max-width: 990px) {
			height: auto;
			padding: 50px 0;
			overflow: hidden;
		}

		.wrapper {
			text-align: left;
			font-size: 20px;
			line-height: 1.2;

			@media only screen and (max-width: 990px) {
				width: 95%;
				padding: 0 5%;
				font-size: 18px;
			}
		}

		.footer-heading {
			display: inline-block;
			vertical-align: middle;
			width: 47%;

			@media only screen and (max-width: 1500px) {
				padding: 0 0 0 10%;
			}

			@media only screen and (max-width: 990px) {
				width: 100%;
				display: block;
				padding: 0;
			}

			h2 {
				padding: 0;
				margin: 0;
				color: #fff;
				font-size: 4vw;
				font-weight: bold;


				@media only screen and (max-width: 990px) {
					text-align: center;
					margin: 0 auto 30px;
					display: block;
					float: none;
					font-size: 30px;
				}
			}
		}

		.wrap {
			display: inline-block;
			vertical-align: middle;
			width: 52%;

			@media only screen and (max-width: 990px) {
				width: 100%;
				display: block;
			}

			.inner,
			.inner-inner {
				display: inline-block;
				vertical-align: middle;

				@media only screen and (max-width: 990px) {
					width: 100%;
					display: block;
				}
			}

			.inner {
				width: calc(100% - 160px);

				@media only screen and (max-width: 990px) {
					width: 100%;
					display: block;
				}
			}

			.inner-inner {
				width: 150px;


				@media only screen and (max-width: 990px) {
					width: 100%;
					display: block;

					img {
						display: block;
						margin: 0 auto 20px;
					}
				}
			}
		}

		p,
		a[href^="mailto:"] {
			margin: 0 0 20px 0;
			color: #fff;

			span {
				display: block;
				font-weight: bold;
			}

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0 auto 10px;
				display: block;
				float: none;
			}
		}

		a {
			@media only screen and (max-width: 990px) {
				word-break: break-all;
			}
		}

		.address {}

		.telephone {}

		a[href^="mailto:"] {
			display: block;
			margin: 0;
			position: relative;

			&:before {
				@include before(0, 2px);
				background: #fff;
				left: 0;
				bottom: -5px;
				transition: .3s;
			}

			&:hover {
				&:before {
					width: 100%;
				}
			}
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.sub-footer {
		height: 30px;
		line-height: 30px;
		overflow: hidden;
		@extend .clearfix;
		margin: -50px auto 0;
		z-index: 99;
		position: relative;

		@media only screen and (max-width: 990px) {

			height: auto;
			line-height: normal;
			padding: 5px 0;
			margin: 0 auto;
			background: rgba(#2f1840, .95);
			padding: 0 0 20px 0;

		}

		p {
			font-size: 13px;
			color: #fff;
			margin: 0;
			font-weight: bold;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 5px auto;
			}
		}

		#copyright {
			float: left;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#credit {
			float: right;

			@media only screen and (max-width: 990px) {
				float: none;
			}
		}

		#copyright,
		#credit {

			a {
				color: #fff;

				@media only screen and (max-width: 990px) {
					margin: 0;
					display: inline-block;
				}

				&:last-of-type {
					color: #fff;
					text-decoration: underline;
				}
			}
		}
	}

	// -----------------------------------//
	// TOP BUTTON
	// -----------------------------------//

	.top-btn {
		position: fixed;
		z-index: 99999;
		bottom: 50px;
		right: 30px;
		display: block;
		width: 45px;
		height: 45px;
		background: #2f1840;
		border-radius: 50%;
		color: #fff;
		font-size: 25px;
		text-align: center;
		opacity: 0;
		transform: scale(0);
		transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		box-shadow: 0 0 0 2px #fff;

		@media only screen and (max-width: 1199px) {
			display: none;
		}
	}

	.show-top-btn {
		opacity: 1;
		transform: scale(1);
	}

	// -----------------------------------//
	// AWARDS
	// -----------------------------------//

	#awards {
		width: 100%;
		overflow: hidden;
		background: #fff;
		padding: 20px 0;

		.CMSawards {
			overflow: hidden;
			width: 100%;
			height: 100%;
			margin: 0 auto !important;
			background: none !important;

			ul {
				margin: 0;

				li {
					background: none;
					padding: 0 5px;
					margin: 0 10px;
					display: inline-block;

					img {
						max-height: 70px;
						transform: none !important;
					}
				}
			}
		}

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}