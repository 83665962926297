// ----------------------------------- WELCOME SEC

#welcome-sec {
    padding: 100px 0 400px;
    background: url(/i/design/welcome-bg.jpg) no-repeat top left;
    position: relative;
    border-top: 5px solid #fff;
    background-size: cover;

    @media only screen and (max-width: 1800px) {
        padding: 100px 0 350px;
    }

    @media only screen and (max-width: 1500px) {
        padding: 100px 0 300px;
    }

    @media only screen and (max-width: 1350px) {
        padding: 100px 0 280px;
    }

    .wrapper {
        width: 90%;
        color: #fff;
    }

    .wrap {
        background: #473863;
        border-radius: 50px 50px 100px 10px;
        width: 65%;
        margin: 0 0 0 auto;
        padding: 50px 100px 100px 120px;
        position: relative;
        box-shadow: 0 0 0 15px rgba(#fff, .2);
        outline: 2px solid #fff;
        outline-offset: 15px;

        @media only screen and (max-width: 1800px) {
            width: 70%;
            padding: 40px 80px 80px 100px;
        }

        @media only screen and (max-width: 1500px) {
            width: 75%;
            padding: 30px 70px 70px 80px;
        }

        @media only screen and (max-width: 990px) {
            border-radius: 20px;
            padding: 20px 5%;
            width: 95%;
            margin: 0 auto;
            box-shadow: 0 0 0 10px rgba(#fff, .2);
            outline: 2px solid #fff;
            outline-offset: 10px;
        }

        &:before {
            @include before(376px, 486px);
            background: url(/i/design/shapes-1.png) no-repeat center;
            left: -430px;
            bottom: -150px;

            @media only screen and (max-width: 990px) {
                display: none;
            }
        }

        .welc-img {
            @include box(380px);
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(-50%, 30%);
            border-radius: 50%;
            border: 5px solid #473863;
            box-shadow: 0 0 0 8px #fff;

            @media only screen and (max-width: 1500px) {
                @include box(250px);
                transform: translate(-50%, -30%);
            }

            @media only screen and (max-width: 990px) {
                position: relative;
                left: 0;
                right: 0;
                margin: 50px auto;
                bottom: auto;
                transform: none;
            }
        }

        p {
            padding: 0 0 0 200px;
            line-height: 1.3;

            @media only screen and (max-width: 1500px) {
                padding: 0 0 0 100px;
            }

            @media only screen and (max-width: 990px) {
                padding: 0;
            }

            &:first-of-type {
                font-weight: bold;
                font-size: 22px;
            }
        }
    }

    h2 {
        color: #fff;
        font-size: 5vw;
        margin: 0 0 30px 0;
        font-weight: bold;

        @media only screen and (max-width: 990px) {
            font-size: 50px;
            text-align: center;
            margin: 0 auto 30px;
        }
    }

    p {}

    .btn-wrap {
        position: absolute;
        right: 200px;
        bottom: -40px;

        @media only screen and (max-width: 990px) {
            position: relative;
            left: 0;
            right: 0;
            margin: 50px auto 0;
            bottom: auto;
            transform: none;
            text-align: center;
        }
    }

    .read-more {
        &:hover {
            background: #473863;
            color: #fff;
            box-shadow: 0 0 0 2px #fff;
        }
    }
}

.read-more {
    display: inline-block;
    height: 65px;
    line-height: 57px;
    text-align: center;
    background: #fff;
    border-radius: 50px;
    padding: 0 80px;
    border: 5px solid #473863;
    box-shadow: 0 0 0 5px rgba(#fff, .3);
    color: #422E65;
    font-size: 20px;
    max-width: 300px;
    font-weight: bold;
    transition: .3s;

    @media only screen and (max-width: 1800px) {
        padding: 0 60px;
        font-size: 19px;
    }

    @media only screen and (max-width: 1500px) {
        padding: 0 50px;
        height: 60px;
        line-height: 52px;
        font-size: 18px;
    }

    @media only screen and (max-width: 990px) {
        display: block;
        margin: 0 auto 20px;
        max-width: 250px;
        width: 200px;
        padding: 0;
    }
}

.js-modal-btn a {
    @extend .read-more;
    margin: 0 0 0 20px;

    @media only screen and (max-width: 990px) {
        display: block;
        margin: 0 auto 20px;
    }
}

// ----------------------------------- NEXT SEC

#vision-sec {
    position: relative;
    padding: 100px 0 260px 0;
    background: rgba(#2d1c45, .95) url(/i/design/feathers.png) no-repeat top 300px left 50px;
    border-top: 5px solid #74afdb;

    @media only screen and (max-width: 990px) {
        padding: 50px 0;
        background: rgba(#2d1c45, .95);
    }

    &:before {
        @include before(40%, 100%);
        background: url(/i/design/big-dots.jpg) no-repeat center;
        right: 0;
        top: 0;

        @media only screen and (max-width: 990px) {
            display: none;
        }
    }

    &:after {
        @include before(168px, 138px);
        background: url(/i/design/shapes-2.png) no-repeat center;
        right: 50px;
        bottom: 120px;

        @media only screen and (max-width: 990px) {
            display: none;
        }
    }

    .wrapper {
        width: 90%;

        @media only screen and (max-width: 990px) {
            width: 95%;
            margin: 0 auto;
        }
    }

    .btns {
        display: inline-block;
        text-align: center;
        position: relative;
        top: -250px;
        margin: 0 auto -150px;

        @media only screen and (max-width: 990px) {
            margin: 0 auto;
            top: auto;
        }

        li {
            display: inline-block;
            max-width: calc(100% / 4 - 30px);
            height: 433px;
            padding: 0;
            background: none;
            vertical-align: top;
            margin: 0 10px;
            overflow: hidden;
            position: relative;
            box-shadow: 0 0 0 5px #fff, 1.414px 1.414px 8px 0px rgba(0, 0, 0, 0.56);
            z-index: 1;
            transition: .3s;

            @media only screen and (max-width: 990px) {
                max-width: 300px;
                margin: 10px;
                box-shadow: 0 0 0 2px #fff, 1.414px 1.414px 8px 0px rgba(0, 0, 0, 0.56);
            }

            &:nth-of-type(1) {
                background: #fff;
                border-radius: 5px 45px 5px 5px;
                filter: saturate(0%);

                &:before,
                &::after {
                    @include before(100%, 300px);
                    background: linear-gradient(0deg, rgba(#fff, 1) 0%, rgba(0, 0, 0, 0) 100%);
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    user-select: none;
                }

                span {
                     color: #000;
                }
            }

            &:nth-of-type(2) {
                background: #479c81;
                border-radius: 5px 5px 5px 45px;

                &:before,
                &::after {
                    @include before(100%, 300px);
                    background: linear-gradient(0deg, rgba(#479c81, 1) 0%, rgba(0, 0, 0, 0) 100%);
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    user-select: none;
                }
            }

            &:nth-of-type(3) {
                background: #54466e;
                border-radius: 45px 5px 5px 5px;

                &:before,
                &::after {
                    @include before(100%, 300px);
                    background: linear-gradient(0deg, rgba(#54466e, 1) 0%, rgba(0, 0, 0, 0) 100%);
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    user-select: none;
                }
            }

            &:nth-of-type(4) {
                background: #151515;
                border-radius: 5px 5px 45px 5px;

                &:before,
                &::after {
                    @include before(100%, 300px);
                    background: linear-gradient(0deg, rgba(#151515, 1) 0%, rgba(0, 0, 0, 0) 100%);
                    bottom: 0;
                    left: 0;
                    pointer-events: none;
                    user-select: none;
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                mix-blend-mode: luminosity;
                opacity: .4;
                transition: .3s;
                position: relative;
            }

            span {
                position: absolute;
                left: 30px;
                bottom: 40px;
                color: #fff;
                font-size: 2vw;
                line-height: 1;
                z-index: 99;
                text-align: left;
                font-weight: bold;

                @media only screen and (max-width: 990px) {
                    font-size: 25px;
                }
            }

            &:hover {
                img {
                    opacity: .9;
                }
            }
        }

        &:hover {
            li:not(:hover) img {
                opacity: .1;
            }
        }
    }

    .left {
        width: 50%;
        float: left;
        padding: 0 11%;

        @media only screen and (max-width: 1800px) {
            padding: 0 8%;
        }

        @media only screen and (max-width: 990px) {
            width: 100%;
            float: none;
            display: block;
            padding: 50px 5%;
        }

        h2 {
            margin: 0 0 50px 0;
            color: #846B99;
            font-weight: bold;
            font-size: 2vw;
            line-height: 1;

            @media only screen and (max-width: 1800px) {
                margin: 0 0 30px 0;
            }

            @media only screen and (max-width: 1500px) {
                font-size: 30px;
            }

            @media only screen and (max-width: 990px) {
                text-align: center;
                margin: 0 auto 30px;
            }

        }

        p {
            color: #fff;
            margin: 0 0 50px 0;
            font-size: 20px;

            @media only screen and (max-width: 990px) {
                text-align: center;
            }

            &:first-of-type {
                font-size: 22px;
                // margin: 0 0 10px 0;
            }
        }
    }

    .right {
        width: 40%;
        float: right;
        text-align: right;
        padding: 0 8%;

        @media only screen and (max-width: 1500px) {
            padding: 0 5%;
        }

        @media only screen and (max-width: 990px) {
            width: 100%;
            float: none;
            display: block;
            padding: 0;
        }

        h2 {
            color: #422e65;
            margin: 0;
            line-height: 1.1;
            font-size: 2.7vw;
            font-weight: bold;
            text-transform: uppercase;

            @media only screen and (max-width: 1500px) {
                font-size: 40px;
            }

            @media only screen and (max-width: 990px) {
                text-align: center;
                color: #fff;
            }
        }
    }

    .read-more {
        border: 5px solid #846B99;
        box-shadow: 0 0 0 3px rgba(#fff, 1);
        color: #846B99;

        &:hover {
            background: #846B99;
            color: #fff;
            box-shadow: 0 0 0 2px #fff;
        }
    }
}

#schools-sec {
    background: rgba(#846B99, .95);

    @media only screen and (max-width: 990px) {
        padding: 20px 5% 50px;
    }

    .wrapper {
        width: 90%;
        padding: 80px 50px;
        border-radius: 50px 50px 100px 50px;
        background: #32214d url(/i/design/big-fev.png) no-repeat center;
        box-shadow: 0 0 0 15px rgba(#fff, .2);
        outline: 2px solid #fff;
        outline-offset: 15px;
        position: relative;
        z-index: 999;
        top: -100px;

        @media only screen and (max-width: 1800px) {
            padding: 50px;
            border-radius: 40px 40px 80px 40px;
        }

        @media only screen and (max-width: 1500px) {
            padding: 40px;
            border-radius: 30px 30px 70px 30px;
        }

        @media only screen and (max-width: 990px) {
            padding: 30px;
            border-radius: 20px 20px 50px 20px;
            width: 95%;
            box-shadow: 0 0 0 10px rgba(#fff, .2);
            outline: 2px solid #fff;
            outline-offset: 10px;
            top: 20px;
        }


        h2 {
            text-align: center;
            color: #fff;
            font-size: 4vw;
            margin: 0 auto 60px;
            font-weight: bold;

            @media only screen and (max-width: 1500px) {
                font-size: 35px;
                margin: 0 auto 40px;
            }

            @media only screen and (max-width: 990px) {
                font-size: 30px;
            }

            span {
                color: #fff;
            }
        }

        // BXSLIDER

        ul#bxslider {
            width: 990px !important;
            max-width: 95%;
            margin: 0 auto;
            text-align: center;
            left: 0;
            right: 0;

            li {
                width: 100% !important;
                margin: 0 auto;
                text-align: left;
                padding: 0;
                background: none;
                color: #fff;
                font-size: 35px;
                line-height: 1.2;
                font-weight: bold;

                @media only screen and (max-width: 1800px) {
                    font-size: 30px;
                }

                @media only screen and (max-width: 1500px) {
                    font-size: 25px;
                }

                @media only screen and (max-width: 990px) {
                    font-size: 20px;
                }

                &:before {
                    @include before(99px, 61px);
                    background: url(/i/design/quotes-o.png) no-repeat center;
                    left: -150px;
                    top: 50%;
                    transform: translateY(-70%);

                    @media only screen and (max-width: 1500px) {
                        display: none;
                    }
                }

                &:after {
                    @include before(99px, 61px);
                    background: url(/i/design/quotes-c.png) no-repeat center;
                    right: -100px;
                    top: 50%;
                    transform: translateY(-70%);

                    @media only screen and (max-width: 1500px) {
                        display: none;
                    }
                }

                span {
                    display: block;
                    text-align: right;
                    margin: 30px 0 0 0;
                    font-weight: bold;
                    color: #fff;

                    @media only screen and (max-width: 990px) {
                        margin: 20px 0;
                    }
                }
            }
        }

        .read-more {
            display: block;
            margin: 50px auto 0;
            border: 3px solid #846B99;
            box-shadow: 0 0 0 3px rgba(#fff, 1);
            color: #846B99;

            &:hover {
                background: #846B99;
                color: #fff;
                box-shadow: 0 0 0 2px #fff;
            }

            @media only screen and (max-width: 990px) {
                width: 200px;
                margin: 20px auto 0;
            }
        }
    }
}






// -----------------------------------//
// EMPTY QUEERS
// -----------------------------------//

@media only screen and (max-width: 1800px) {}

@media only screen and (max-width: 1500px) {}

@media only screen and (max-width: 1350px) {}

@media only screen and (max-width: 990px) {}

@media only screen and (max-width: 650px) and (orientation:landscape) {}

// -----------------------------------//
// BROWSER SPECIFIC
// -----------------------------------//

// IE fixes
@media all and (-ms-high-contrast: none),
all and (-ms-high-contrast: active),
screen\0,
screen\9 {}

// Edge fixes
@supports (-ms-ime-align:auto) {}