// BXSLIDER CONTROLS - INDICATORS

// .bx-controls{ display: block; text-align: center; margin: 30px auto; border-radius: 50%;

//   .bx-pager-item { display: inline-block; height: 20px; width: 20px; margin: 0 8px; border-radius: 50%;

//     .bx-pager-link { height: 20px; width: 20px; background: #947cb3; overflow: hidden;color: transparent; display: inline-block; margin: 0 auto; border-radius: 50%;}

//       .active { background: #344055;}
//   }
// }

// BXSLIDER CONTROLS - ARROWS

// .bx-prev {position: absolute; color: transparent; left: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-left.png) no-repeat center; background-size: contain;}

// .bx-next {position: absolute; color: transparent; right: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-right.png) no-repeat center; background-size: contain;}


// for c_import in the CMS

// [bx-container] {margin: 50px auto; left: 0; right: 0; top: 0;}
// [bx-container]  li {border: 5px dashed #fff; background: #222!important; padding: 10px; color: #fff!important;}